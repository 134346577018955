import React from "react"
import { Row, Col } from "react-flexbox-grid"

import Interstitial from "../../../components/ExternalInterstitial/Interstitial"
import ExternalLink from "../../../components/ExternalLink"
import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"

import logo1 from "../../../assets/images/logo_Narcolepsy_Network.png"
import logo2 from "../../../assets/images/logo_WakeUp_Narcolepsy.png"
import logo3 from "../../../assets/images/logo_Project_Sleep.png"
import logo4 from "../../../assets/images/logo_National_Sleep_Foundation.png"
import logo5 from "../../../assets/images/logo_AAHS.png"
import logo6 from "../../../assets/images/logo_Global_Jeans.png"
import logo7 from "../../../assets/images/logo_NORD.png"
import logo8 from "../../../assets/images/logo_KnowNarcolepsy.png"
import logo9 from "../../../assets/images/logo_FDA.png"
import headerHero from "../../../assets/images/header_education.png"

import "./index.scss"

const narcNetworkTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.narcolepsynetwork.org",
}
const wakeupTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.wakeupnarcolepsyorg",
}
const projectSleepTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.project-sleep.com",
}
const sleepfoundationTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.sleepfoundation.org",
}
const sleepTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.sleep.org/ ",
}
const sleepalliesTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.sleepallies.org",
}
const globalTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.globalgenes.org",
}
const rareTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.rarediseases.org",
}
const knTracking = {
  category: "ExternalLink",
  action: "click",
  label: "www.KnowNarcolepsy.com",
}
const fdaTracking = {
  category: "Button",
  action: "Download",
  label: "FDA Voice of Patient",
}
const pageTitle =
  "Educational Resources for Narcolepsy and Other Rare Diseases | Harmony Biosciences"
const pageDescription =
  "Discover educational resources for rare disease information and patient support organizations. Download the FDA Voice of the Patient Narcolepsy report."
const pageKeywords = "narcolepsy support group - 170"
const support = () => (
  <Layout jobCode="US-HAR-2200050/Jun 2022">
    <div id="support-groups">
      <HelmetWrapper
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        slug="/patients/educational-resources"
      />
      <Hero bg={headerHero}>
        <h1>Educational Resources</h1>
      </Hero>
      <ContentBlock color="blue">
        <Row between="xs">
          <Col xs={12} md={12}>
            <h2>NARCOLEPSY GROUPS</h2>
          </Col>
        </Row>
        <Row between="xs">
          <Col xs={12} md={6}>
            <img src={logo1} className="" />
            <p>
              <strong>Narcolepsy Network</strong>
            </p>
            <p>
              A national patient support organization focused on educating and
              empowering people with narcolepsy as well as the public at large
            </p>
            <ExternalLink
              href="https://www.narcolepsynetwork.org"
              gaTracking={narcNetworkTracking}
            >
              www.narcolepsynetwork.org
            </ExternalLink>
          </Col>
          <Col xs={12} md={6}>
            <img src={logo2} className="" />
            <p>
              <strong>Wake Up Narcolepsy</strong>
            </p>
            <p>
              An organization dedicated to supporting narcolepsy awareness and
              research to find a cure
            </p>
            <ExternalLink
              href="https://www.wakeupnarcolepsy.org"
              gaTracking={wakeupTracking}
            >
              www.wakeupnarcolepsy.org
            </ExternalLink>
          </Col>
        </Row>
      </ContentBlock>
      <ContentBlock color="white">
        <Row between="xs">
          <Col xs={12} md={12}>
            <h2>SLEEP GROUPS</h2>
          </Col>
        </Row>
        <Row between="xs">
          <Col xs={12} md={6}>
            <img src={logo3} className="" />
            <p>
              <strong>Project Sleep</strong>
            </p>
            <p>
              A nonprofit organization raising awareness about sleep health and
              sleep conditions
            </p>
            <ExternalLink
              href="https://www.project-sleep.com"
              gaTracking={projectSleepTracking}
            >
              www.project-sleep.com
            </ExternalLink>
          </Col>
          <Col xs={12} md={6}>
            <img src={logo4} className="" />
            <p>
              <strong>National Sleep Foundation</strong>
            </p>
            <p>
              A nonprofit organization dedicated to improving health and
              well-being through sleep education and advocacy
            </p>
            <p>
              <ExternalLink
                href="https://www.thensf.org"
                gaTracking={sleepfoundationTracking}
              >
                www.thensf.org
              </ExternalLink>
            </p>
          </Col>
        </Row>
      </ContentBlock>
      <ContentBlock color="green">
        <Row between="xs">
          <Col xs={12} md={12}>
            <h2>RARE DISEASE GROUPS</h2>
          </Col>
        </Row>
        <Row between="xs">
          <Col xs={12} md={6}>
            <img src={logo6} className="" />
            <p>
              <strong>Global Genes: Allies in Rare Disease</strong>
            </p>
            <p>
              A global patient advocacy organization that focuses on connecting,
              empowering, and inspiring the rare disease community
            </p>
            <ExternalLink
              href="https://www.globalgenes.org"
              gaTracking={globalTracking}
            >
              www.globalgenes.org
            </ExternalLink>
          </Col>
          <Col xs={12} md={6}>
            <img src={logo7} className="" />
            <p>
              <strong>National Organization for Rare Disorders</strong>
            </p>
            <p>
              A patient advocacy organization committed to the identification,
              treatment, and cure of rare disorders through programs of
              education, advocacy, research, and patient services
            </p>
            <ExternalLink
              href="https://www.rarediseases.org"
              gaTracking={rareTracking}
            >
              www.rarediseases.org
            </ExternalLink>
          </Col>
        </Row>
      </ContentBlock>
      <ContentBlock color="white">
        <Row between="xs">
          <Col xs={12} md={12}>
            <h2>OTHER NARCOLEPSY RESOURCES</h2>
          </Col>
        </Row>
        <Row between="xs">
          <Col xs={12} md={6}>
            <img src={logo8} className="" />
            <p>
              <strong>
                Know Narcolepsy<sup>&reg;</sup>
              </strong>
            </p>
            <p>
              Know Narcolepsy seeks to advance understanding of the neurobiology
              of narcolepsy, the significant impact symptoms can have on people
              living with narcolepsy and their loved ones, and to respond to the
              unmet needs that are fueling research, to ultimately help improve
              the lives of people living with narcolepsy.
            </p>
            <p>
              <ExternalLink
                href="https://knownarcolepsy.com/"
                gaTracking={knTracking}
              >
                www.KnowNarcolepsy.com
              </ExternalLink>
            </p>
            <p className="footnote">
              Know Narcolepsy is a registered trademark of Harmony Biosciences.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <img src={logo9} className="" />
            <p>
              <strong>FDA Voice of the Patient: Narcolepsy</strong>
            </p>
            <p>
              A report from the U.S. Food and Drug Administration’s
              patient-focused drug development initiative
            </p>
            <ExternalLink
              href="https://www.fda.gov/media/88736/download"
              gaTracking={fdaTracking}
            >
              <button>DOWNLOAD PDF</button>
            </ExternalLink>
          </Col>
        </Row>
      </ContentBlock>
      <ContentBlock>
        <Row>
          <Col>
            <p style={{ paddingLeft: "10px" }} className="footnote">
              The third-party sites listed on this page are for informational
              purposes only. Harmony Biosciences strives to provide the most
              useful and accurate resources available to people living with
              narcolepsy, but we do not endorse and are not responsible for the
              content found on these third-party websites.
            </p>
          </Col>
        </Row>
      </ContentBlock>
      <Interstitial />
    </div>
  </Layout>
)

export default support
